
import ApiService from "@/core/services/ApiService"
import { defineComponent, computed, onMounted, ref } from "vue"
import { Edit, Plus, Close } from "@element-plus/icons-vue"
import { DateFormatTypes, dateForm, today } from "@/core/helpers/dateformat"
import { SelectModel } from "@/core/models/SelectModel"
import { Invoice, InvoiceType } from "@/core/models/Invoice"
import { GeneratePaymentSchedule } from "@/core/models/GeneratePaymentSchedule"

import { FormInstance } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import swalConfirm from "@/core/helpers/swal-confirm"
import { TagType } from "@/core/models/Tag"
import { InternalRuleItem, Value } from "async-validator"
import { PaymentScheduleItem } from "@/core/models/PaymentScheduleItem"
import { InvoiceDetail } from "@/core/models/InvoiceDetail"
import { staticCurrencyList } from "@/core/enums/enums"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"

export default defineComponent({
  name: "revenue",
  components: { ElInputCurrency },
  setup() {
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isCashBookSelectLoading = ref(false)
    const isAccountCardSelectLoading = ref(false)
    const isRevenueTypeSelectLoading = ref(false)

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const accountCardList = ref<SelectModel[]>([])
    const cashBookList = ref<SelectModel[]>([])
    const revenueTypeList = ref<SelectModel[]>([])

    const invoiceData = ref<Invoice>({
      description: "",
      invoiceDetails: [{ vatPrice: 0 } as InvoiceDetail],
      transactionDate: today(),
    } as Invoice)

    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()
    const activeCashBookId = ref<string>()

    const ruleFormRef = ref<FormInstance>()

    const isContinuous = ref(false)
    const continuousStartDate = ref("")
    const continuousEndDate = ref("")
    const noInstallment = ref(false)

    const paymentSchedule = ref({
      installmentAmount: 1,
      downPayment: 0,
      paymentStartDate: new Date(),
    } as GeneratePaymentSchedule)

    const revenueTypeTreeSelectProps = {
      label: "name",
      value: "id",
      children: "childs",
    }

    const currencyList = ref(staticCurrencyList)

    const ValidateCashBook = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      if ((!value || value == "") && !activeCashBookId.value && invoiceData.value.hasPayment) {
        callback(new Error("Lütfen Hesap Seçiniz!"))
      } else callback()
    }

    const onNoInstallmentChange = () => {
      paymentSchedule.value = {
        installmentAmount: 1,
        downPayment: 0,
        paymentStartDate: new Date(),
      } as GeneratePaymentSchedule
    }

    const rules = ref({
      activeBusinessId: getRule(RuleTypes.SELECT, "İşletme"),
      accountCardId: getRule(RuleTypes.SELECT, "Cari"),
      tagId: getRule(RuleTypes.SELECT, "Gider Türü"),
      currencyUnit: getRule(RuleTypes.SELECT, "Para Birimi"),
      activeCashBookId: [
        {
          validator: ValidateCashBook,
          trigger: "change",
        },
      ],
      paymentStartDate: getRule(RuleTypes.DATE, "Başlangıç Tarihi"),
      transactionDate: getRule(RuleTypes.DATE, "İşlem Tarihi"),
      hasPayment: getRule(RuleTypes.DATE, "Durum"),
    })

    const formData = computed(() => ({
      activeBusinessId: activeBusinessId.value,
      paymentStartDate: paymentSchedule.value.paymentStartDate,
      ...invoiceData.value,
    }))

    const activeSuffix = computed(() =>
      invoiceData.value.currencyUnit > 0
        ? currencyList.value.find(x => x.id == invoiceData.value.currencyUnit)?.suffix
        : ""
    )

    const totalAmounts = computed(() => {
      let totalPrice = 0,
        subPrice = 0,
        vatPrice = 0

      invoiceData.value.invoiceDetails.forEach(element => {
        totalPrice += element.totalPrice
        subPrice += element.subPrice
        vatPrice += element.vatPrice
      })

      return { totalPrice, subPrice, vatPrice }
    })

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length === 1) {
        activeBusinessId.value = businessList.value[0].id
        await OnChangeBusiness(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBranchSelectLoading.value = false
    }

    const getBusinessAccountCard = async businessId => {
      isAccountCardSelectLoading.value = true
      const { data } = await ApiService.get("account-card/business/" + businessId)
      accountCardList.value = data.map(x => ({ id: x.id, name: x.shortName }))
      if (accountCardList.value.length === 1) {
        invoiceData.value.accountCardId = accountCardList.value[0].id
      }
      isAccountCardSelectLoading.value = false
    }

    const getBranchAccountCard = async branchId => {
      isAccountCardSelectLoading.value = true
      const { data } = await ApiService.get("account-card/branch/" + branchId)
      accountCardList.value = data.map(x => ({ id: x.id, name: x.shortName }))
      if (accountCardList.value.length === 1) {
        invoiceData.value.accountCardId = accountCardList.value[0].id
      }
      isAccountCardSelectLoading.value = false
    }

    const getBusinessCashBookList = async businessId => {
      isCashBookSelectLoading.value = true
      activeBusinessId.value = businessId != undefined ? businessId : activeBusinessId.value
      const { data } = await ApiService.get("cash-book/business/" + activeBusinessId.value)
      isCashBookSelectLoading.value = false
      cashBookList.value = data.map(x => ({ id: x.id, name: x.name }))
    }

    const getBranchCashBookList = async branchId => {
      isCashBookSelectLoading.value = true
      activeBranchId.value = branchId != undefined ? branchId : activeBranchId.value
      const { data } = await ApiService.get("cash-book/branch/" + activeBranchId.value)
      isCashBookSelectLoading.value = false
      cashBookList.value = data.map(x => ({ id: x.id, name: x.name }))
    }

    const getRevenueTypeList = async () => {
      isRevenueTypeSelectLoading.value = true
      const query = {
        params: {
          tagType: TagType.RevenueType,
        },
      }
      const { data } = await ApiService.query(`tag/childs`, query)
      revenueTypeList.value = data
      isRevenueTypeSelectLoading.value = false
    }

    function addInvoice(formEl: FormInstance | undefined) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const confirmResult = await swalConfirm()

          if (confirmResult.isConfirmed) {
            paymentSchedule.value.totalPrice = totalAmounts.value.totalPrice
            const { data: generateData } = await ApiService.post(
              "payment-schedule/generate",
              paymentSchedule.value
            )

            invoiceData.value.paymentSchedules = [...generateData] as PaymentScheduleItem[]
            invoiceData.value.invoiceType = InvoiceType.SaleInvoice

            if (activeCashBookId.value != undefined && activeCashBookId.value.length > 0)
              invoiceData.value.cashbookId = activeCashBookId.value as string

            const { status } = await ApiService.post("invoice/add", {
              ...invoiceData.value,
              invoiceDetails: invoiceData.value.invoiceDetails.map(x => {
                x.description = ""
                return x
              }),
              ...totalAmounts.value,
            })

            if (status == 200) {
              paymentSchedule.value = { installmentAmount: 1 } as GeneratePaymentSchedule
              branchList.value = cashBookList.value = accountCardList.value = [] as SelectModel[]

              activeCashBookId.value =
                activeBusinessId.value =
                activeBranchId.value =
                activeCashBookId.value =
                  undefined

              paymentSchedule.value = {
                installmentAmount: 1,
                downPayment: 0,
                paymentStartDate: new Date(),
              } as GeneratePaymentSchedule

              branchList.value = cashBookList.value = accountCardList.value = [] as SelectModel[]

              invoiceData.value = {
                invoiceDetails: [{ vatPrice: 0 } as InvoiceDetail],
                transactionDate: today(),
                description: "",
              } as Invoice

              formEl.resetFields()
            }
          }
        }
      })
    }

    const OnChangeBusiness = async businessId => {
      activeBranchId.value = undefined
      invoiceData.value.accountCardId =
        invoiceData.value.tagId =
        activeCashBookId.value =
          "" as string
      accountCardList.value = cashBookList.value = [] as SelectModel[]
      await getBusinessCashBookList(businessId)
      await getBranchList(businessId)
      await getBusinessAccountCard(businessId)
    }

    const OnChangeBranch = branchId => {
      invoiceData.value.accountCardId = invoiceData.value.cashbookId = "" as string
      accountCardList.value = [] as SelectModel[]
      if (activeBranchId.value != "" && activeBranchId.value != undefined) {
        getBranchCashBookList(branchId)
        getBranchAccountCard(branchId)
      }
    }

    onMounted(() => {
      getBusinessList()
      getRevenueTypeList()
    })

    const addInvoiceDetail = () => {
      const data = invoiceData.value
      invoiceData.value = {
        ...invoiceData.value,
        invoiceDetails: [...data.invoiceDetails, {} as InvoiceDetail],
      }
    }

    const removeInvoiceDetail = index => {
      const data = invoiceData.value
      data.invoiceDetails.splice(index, 1)
      invoiceData.value = {
        ...invoiceData.value,
        invoiceDetails: [...data.invoiceDetails],
      }
    }

    return {
      dateForm,
      DateFormatTypes,
      revenueTypeTreeSelectProps,
      isCashBookSelectLoading,
      isRevenueTypeSelectLoading,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      activeSuffix,
      cashBookList,
      businessList,
      branchList,
      accountCardList,
      currencyList,
      revenueTypeList,
      activeBusinessId,
      activeBranchId,
      activeCashBookId,
      paymentSchedule,
      isContinuous,
      continuousStartDate,
      continuousEndDate,
      Edit,
      getBusinessCashBookList,
      getBranchCashBookList,
      OnChangeBusiness,
      OnChangeBranch,
      addInvoice,
      ruleFormRef,
      invoiceData,
      rules,
      formData,
      noInstallment,
      onNoInstallmentChange,
      Plus,
      Close,
      addInvoiceDetail,
      removeInvoiceDetail,
    }
  },
})
